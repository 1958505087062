import PropTypes from 'prop-types';
import { linksShape } from './sharedShapes';

const diversityShape = PropTypes.shape({
  name: PropTypes.string,
});

const contactOptionsShape = PropTypes.shape({
  email: PropTypes.bool,
  phone: PropTypes.bool,
  sms: PropTypes.bool,
});

const imageShape = PropTypes.shape({
  height: PropTypes.number,
  size: PropTypes.number,
  square: PropTypes.shape({
    main: PropTypes.string,
  }),
  wide: PropTypes.shape({
    main: PropTypes.string,
  }),
  width: PropTypes.number,
});

const avatarCoverShape = PropTypes.shape({
  images: PropTypes.objectOf(imageShape),
  color: PropTypes.string,
  placeholder: PropTypes.bool,
});

const locationShape = PropTypes.shape({
  text: PropTypes.string,
  city: PropTypes.string,
  city_slug: PropTypes.string,
  country: PropTypes.string,
  country_slug: PropTypes.string,
});

const preferencesShape = PropTypes.shape({
  email: PropTypes.shape({
    brief_invite: PropTypes.bool,
    brief_update: PropTypes.bool,
    comment_clowd: PropTypes.bool,
    comment_reply: PropTypes.bool,
    follow: PropTypes.bool,
    list_add: PropTypes.bool,
    newsletter: PropTypes.bool,
    private_message: PropTypes.bool,
    published: PropTypes.bool,
    reclowd: PropTypes.bool,
    tag: PropTypes.bool,
    weekly: PropTypes.bool,
  }),
  privacy: PropTypes.shape({
    behance_sync: PropTypes.bool,
    clowd_like: PropTypes.bool,
    comment: PropTypes.bool,
    comment_like: PropTypes.bool,
    download: PropTypes.bool,
    facebook_publish: PropTypes.bool,
    follow: PropTypes.bool,
    location: PropTypes.bool,
    new_clowd: PropTypes.bool,
    play: PropTypes.bool,
    soundcloud_sync: PropTypes.bool,
  }),
  push: PropTypes.shape({
    brief_invite: PropTypes.bool,
    brief_update: PropTypes.bool,
    comment_clowd: PropTypes.bool,
    comment_reply: PropTypes.bool,
    follow: PropTypes.bool,
    list_add: PropTypes.bool,
    marketing: PropTypes.bool,
    private_message: PropTypes.bool,
    published: PropTypes.bool,
    published_followers: PropTypes.bool,
    reclowd: PropTypes.bool,
    tag: PropTypes.bool,
  }),
});

const timeularShape = PropTypes.shape({
  api_key: PropTypes.string,
  api_secret: PropTypes.string,
});

const tourShape = PropTypes.shape({
  accepted_upload_terms: PropTypes.bool,
  completed_collab_walkthrough: PropTypes.bool,
  completed_profile_onboarding: PropTypes.bool,
  feedback_web_enjoyment: PropTypes.bool,
  hide_clowd_pointer: PropTypes.bool,
  hide_subscription_upsell: PropTypes.bool,
  sent_list_reminder: PropTypes.bool,
  tour_web_index: PropTypes.bool,
  tour_web_upload: PropTypes.bool,
  tour_web_viewclowd: PropTypes.bool,
});

const statsShape = PropTypes.shape({
  num_of_projects: PropTypes.number,
  num_of_credits: PropTypes.number,
  num_of_followers: PropTypes.number,
  num_of_following: PropTypes.number,
  num_of_testimonials: PropTypes.number,
});

const skillShape = PropTypes.shape({
  accepted: PropTypes.bool,
  experience: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string,
  slug: PropTypes.string,
  user_id: PropTypes.number,
});

const sectorShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  slug: PropTypes.string,
  user_id: PropTypes.number,
});

const ratingShape = PropTypes.shape({
  avg: PropTypes.number,
  count: PropTypes.number,
});

const websiteShape = PropTypes.shape({
  theme: PropTypes.string,
  website_bio: PropTypes.string,
  links: PropTypes.array,
});

export default PropTypes.shape({
  active: PropTypes.bool,
  auto_approve_pitch_flag: PropTypes.bool,
  available_at: PropTypes.string,
  avatars: avatarCoverShape,
  bio: PropTypes.string,
  braintree_linked: PropTypes.bool,
  company_id: PropTypes.string,
  contact_options: contactOptionsShape,
  covers: avatarCoverShape,
  day_rate: PropTypes.number,
  day_rate_currency: PropTypes.string,
  displayname: PropTypes.string,
  diversity: PropTypes.arrayOf(diversityShape),
  dob: PropTypes.string,
  email: PropTypes.string,
  email_new: PropTypes.string,
  email_verified: PropTypes.bool,
  enterprise: PropTypes.bool,
  external_url_permission: PropTypes.bool,
  featured_roles: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.number,
  joined: PropTypes.string,
  last_active: PropTypes.string,
  links: linksShape,
  location: locationShape,
  organisation: PropTypes.bool,
  phone: PropTypes.string,
  preferences: preferencesShape,
  pro: PropTypes.bool,
  projects_size: PropTypes.number,
  rating: ratingShape,
  sector: PropTypes.string,
  sectors: PropTypes.arrayOf(sectorShape),
  skills: PropTypes.arrayOf(skillShape),
  stats: statsShape,
  stripe_linked: PropTypes.bool,
  subscribed: PropTypes.bool,
  timeular: timeularShape,
  tools: PropTypes.array,
  top_roles: PropTypes.arrayOf(PropTypes.string),
  tour: tourShape,
  type: PropTypes.string,
  username: PropTypes.string,
  verified: PropTypes.bool,
  website: PropTypes.arrayOf(websiteShape),
});
