import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Textarea from 'react-textarea-autosize';
import { format, isValid, addYears } from 'date-fns';
import { TODAY } from 'constants/constants';
import DatePicker from 'components/Shared/DatePicker';
import styles from '../Qualification.module.scss';
import { Button } from 'react-bootstrap';

const QualificationForm = ({
  index,
  updateQualification,
  currentQualification,
  isLast,
  addNewQualification,
}) => {
  const [newQualification, setNewQualification] =
    useState(currentQualification);
  const [qualificationName, setQualificationName] = useState(
    currentQualification?.qualification || ''
  );
  const [startDate, setStartDate] = useState(
    currentQualification?.started_at
      ? new Date(currentQualification.started_at)
      : new Date()
  );
  const [endDate, setEndDate] = useState(
    currentQualification?.ended_at
      ? new Date(currentQualification.ended_at)
      : new Date()
  );
  const [content, setContent] = useState(currentQualification?.content || '');
  const [noName, setNoName] = useState(false);
  const [noEnd, setNoEnd] = useState(false);

  useEffect(() => {
    if (checkChanges()) {
      // Error handling
      setNoName(!qualificationName);
      setNoEnd(!endDate);

      if (!qualificationName || !endDate) {
        return;
      }

      const updates = {
        ...newQualification,
        qualification: qualificationName,
        content: content,
        started_at: formatDate(startDate),
        ended_at: formatDate(endDate),
      };

      // Save user input
      setNewQualification(updates);

      if (updates !== currentQualification) {
        updateQualification(updates, index);
      }
    }
  }, [qualificationName, startDate, endDate, content]);

  const checkChanges = () => {
    if (
      qualificationName !== newQualification.qualification ||
      formatDate(startDate) !== newQualification.started_at ||
      formatDate(endDate) !== newQualification.ended_at ||
      content !== newQualification.content
    ) {
      return true;
    }
    return false;
  };

  const formatDate = (date) => format(date, 'yyyy-MM-dd HH:mm:ss');

  return (
    <div style={isLast ? { display: 'flex', flexDirection: 'column' } : null}>
      <div className="mb lato-bold mt">Qualification name:</div>
      <input
        className="form-control"
        onChange={(event) => setQualificationName(event.target.value)}
        name="qualification"
        placeholder="Eg: Adobe Certified Associate Illustrator"
        type="text"
        value={qualificationName}
      />
      {noName && (
        <div className={`mb ${styles.warning}`}>
          <i>
            <b>You must give a qualification name.</b>
          </i>
        </div>
      )}
      <div className="mb lato-bold mt">Start date:</div>
      <DatePicker
        className="form-control"
        isClearable={false}
        dateFormat="do MMMM yyyy"
        showYearDropdown
        maxDate={addYears(TODAY, 1)}
        onChange={(date) => {
          if (isValid(date)) {
            setStartDate(date);
          }
        }}
        name="started_at"
        placeholderText="Select availability"
        selected={startDate}
      />
      <div className="mb lato-bold mt">End date:</div>
      <DatePicker
        className="form-control"
        isClearable={false}
        dateFormat="do MMMM yyyy"
        showYearDropdown
        maxDate={addYears(TODAY, 10)}
        onChange={(date) => {
          if (isValid(date)) {
            setEndDate(date);
          }
        }}
        name="ended_at"
        placeholderText="Select availability"
        selected={endDate}
      />
      <div className="mb lato-bold mt">Description (optional):</div>
      <Textarea
        className="form-control mb"
        style={{ height: '100%', resize: 'none' }}
        placeholder="Add any extra details you would like to share here."
        onChange={(event) => setContent(event.target.value)}
        name="content"
        value={content}
        minRows={1}
        maxRows={30}
        maxLength="3000"
      />

      {noEnd && (
        <div className={`mb ${styles.warning}`}>
          <i>
            <b>You must give a qualification end date.</b>
          </i>
        </div>
      )}
      {!isLast && <hr />}
      {isLast && (
        <Button
          bsStyle={'primary'}
          style={{
            alignSelf: 'center',
            width: '40%',
            maxWidth: '100%',
            marginTop: '10',
          }}
          disabled={noName || noEnd}
          onClick={() => addNewQualification({})}
        >
          <strong>+ Add Qualification</strong>
        </Button>
      )}
    </div>
  );
};

QualificationForm.propTypes = {
  index: PropTypes.number,
  currentQualification: PropTypes.object,
  updateQualification: PropTypes.func,
  isLast: PropTypes.bool,
  addNewQualification: PropTypes.func,
};

export default QualificationForm;
