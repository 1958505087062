import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Panel } from 'react-bootstrap';

import Loader from 'components/Loader/Loader';
import PortfolioAdverts from './components/ChildComponents/Adverts/PortfolioAdverts';

import Skills from './components/ChildComponents/Skills/Skills';
import Education from './components/ChildComponents/Education/Education';
import Qualification from './components/ChildComponents/Qualification/Qualification';
import IndustryExperience from './components/ChildComponents/IndustryExperience/IndustryExperience';
import Testimonials from './components/ChildComponents/Testimonials/Testimonials';
import Experience from './components/ChildComponents/Experience/Experience';
import Language from './components/ChildComponents/Language/Language';
import Diversity from './components/ChildComponents/Diversity/Diversity';
import Bio from './components/ChildComponents/Bio/Bio';

import styles from './PortfolioAbout.module.scss';
import hocStyles from './components/hoc/hoc.module.scss';

const { panel } = hocStyles;

const PortfolioAbout = ({
  loggedInUser,
  onEmailActivate,
  onReport,
  onTestimonials,
  testimonials,
  sentReport,
  user,
  onShowMore,
  showShowMoreButton,
  showMore,
  locationFindUrl,
  isProjectless,
  isOwn,
  partners,
  loadedPartners,
  ...rest
}) => {
  if (!user) {
    return (
      <div className="container center" {...rest}>
        <Loader loading />
      </div>
    );
  }

  return (
    <Grid fluid className="desktopDisplayLeft">
      {isOwn && (
        <div className={styles.displayForMobile}>
          <PortfolioAdverts
            userLocation={user.location.text}
            loadedPartners={loadedPartners}
            partners={partners}
          />
        </div>
      )}
      <div className="lato-regular" {...rest}>
        <Panel
          id="portfolio-bio"
          className={panel}
          style={{ border: 0, padding: 20 }}
        >
          <Bio
            showMore={showMore}
            showShowMoreButton={showShowMoreButton}
            user={user}
            isOwn={isOwn}
            onShowMore={onShowMore}
            onReport={onReport}
            sentReport={sentReport}
            onEmailActivate={onEmailActivate}
            locationFindUrl={locationFindUrl}
          />
        </Panel>
        {/* Order = [bio (old bio panel for now), skills, experience, employment, language, education & project experience, certificates & awards, industry experience, testimonials] */}
        <div className={isProjectless ? styles.displayForMobile : ''}>
          <Skills
            title="Skills"
            user={user}
            userData={user.skills}
            updateKey="skills"
            isOwner={isOwn ? true : false}
            isEmpty={user.skills.length === 0}
            placeholder="Add Skills to improve your ranking and attract new clients."
            loggedInUser={loggedInUser}
          />
          <Experience
            title="Experience Level"
            user={user}
            userData={user.skills}
            updateKey="skills"
            componentID="experience" // Used to identify components that have a duplicated updateKey
            placeholder="Add your experience level to each of your skills."
            isEmpty={
              isOwn
                ? user.skills.length === 0
                : user.skills.filter((skill) => skill.experience > 0).length ===
                  0
            }
            isOwner={isOwn ? true : false}
            loggedInUser={loggedInUser}
          />
        </div>
        {isOwn && (
          <Diversity
            title="Diversity & Inclusion"
            user={loggedInUser}
            userData={loggedInUser.diversity.map((diversity) => diversity.name)}
            updateKey="diversity"
            isOwner={isOwn ? true : false}
            isEmpty={loggedInUser.diversity.length === 0}
            placeholder="Do you identify as being part of an underrepresented group? Please select any applicable categories."
            permanentInformation={{
              body: (
                <div>
                  <div style={{ paddingBottom: 10, fontStyle: 'italic' }}>
                    This information will not be displayed on your portfolio. It
                    is only visible to you.
                  </div>
                  <div>
                    Clients may have a preference for hiring from an
                    underrepresented group. This is most common with jobs
                    pertaining to social causes.
                  </div>
                  <hr />
                </div>
              ),
            }}
          />
        )}
        <Language
          title="Language"
          user={user}
          userData={user.language}
          updateKey="language"
          placeholder="Add all the languages you speak here."
          isEmpty={user.language.length === 0}
          isOwner={isOwn ? true : false}
        />
        <Panel
          id="portfolio-education"
          className={panel}
          style={{ border: 0, padding: 20 }}
        >
          <Education
            user={user}
            userData={user.education}
            isOwn={isOwn}
            isEmpty={
              user.education.filter((education) => education.organization)
                .length === 0
            }
            placeholder="Add your educational history here."
          />
        </Panel>
        <Panel
          id="portfolio-qualification"
          className={panel}
          style={{ border: 0, padding: 20 }}
        >
          <Qualification
            user={user}
            userData={user.education}
            isOwn={isOwn}
            isEmpty={
              user.education.filter((education) => !education.organization)
                .length === 0
            }
            placeholder="Add your qualifications or awards here."
          />
        </Panel>
        <IndustryExperience
          title="Industry Experience"
          user={user}
          userData={user.sectors.map((sector) => sector.name)}
          updateKey="sectors"
          isOwner={isOwn ? true : false}
          isEmpty={user.sectors.length === 0}
          placeholder="Improve your ranking by adding your industry experience here."
        />
        <Testimonials
          title="Testimonials"
          componentID="testimonials"
          user={user}
          userData={testimonials}
          disableEditMode
          optionalFunction={onTestimonials}
          loggedInUser={loggedInUser ? loggedInUser : {}} // requires object
          isOwner={isOwn ? true : false}
          isEmpty={testimonials.length === 0}
        />
      </div>
    </Grid>
  );
};

PortfolioAbout.propTypes = {
  loggedInUser: PropTypes.object,
  onEmailActivate: PropTypes.func,
  onReport: PropTypes.func,
  onTestimonials: PropTypes.func,
  testimonials: PropTypes.array,
  sentReport: PropTypes.bool,
  user: PropTypes.object,
  onShowMore: PropTypes.func,
  showShowMoreButton: PropTypes.bool,
  showMore: PropTypes.bool,
  locationFindUrl: PropTypes.string,
  isProjectless: PropTypes.bool,
  isOwn: PropTypes.bool,
  partners: PropTypes.array,
  loadedPartners: PropTypes.bool,
};
export default memo(PortfolioAbout);
