import PropTypes from 'prop-types';
import React, { Fragment, memo, useCallback, useMemo } from 'react';
import { Prompt } from 'react-router';

import Loader from 'components/Loader/Loader';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import Button from 'react-bootstrap/lib/Button';
import useAuth from 'hooks/useAuth';
import styles from '../hoc/hoc.module.scss';

const { editBtn, editWrp, loader } = styles;

const Edit = ({
  canEdit = true,
  isHeader,
  editMode,
  cancelEdit,
  updateKey,
  toggleEditMode,
  onEmailActivate,
  style,
  submitEdit,
  updates,
  // componentID is set to updateKey if it is not present, it is used
  // to identify multiple unique components with the same updateKey
  // to prevent multiple green ticks on update
  componentID = updateKey,
  owner,
}) => {
  if (__CLIENT__ && editMode) {
    // Enable navigation prompt
    window.onbeforeunload = () => true;
  }

  const { user, lastUpdated, updating, updated, updateUser } = useAuth();

  const handleUpdate = useCallback(async () => {
    toggleEditMode();
    await updateUser(
      user,
      { ...updates, portfolioUpdate: true },
      false,
      [
        'skills:accepted(null)',
        'sectors',
        'tools',
        'education',
        'language',
        'diversity',
      ],
      '',
      componentID
    );

    if (__CLIENT__) {
      window.onbeforeunload = null;
    }

    // if (res) toggleEditMode();
  }, [user, updates, componentID]);

  const instanceIsUpdating = useMemo(
    () => lastUpdated === componentID,
    [lastUpdated, componentID]
  );

  const isOwner = useMemo(
    () => owner && user && owner.id === user.id,
    [owner, user]
  );

  const pencil = (
    <button
      id={`edit-${componentID}`}
      className={editBtn}
      onClick={canEdit ? toggleEditMode : onEmailActivate}
      type="button"
    >
      <SvgIcon
        size="21"
        icon="pencil"
        color={isHeader ? '#ffffff' : '#666666'}
      />
    </button>
  );

  const saveCancel = (
    <Fragment>
      <Button
        disabled={updating}
        onClick={() => {
          if (__CLIENT__) {
            window.onbeforeunload = null;
          }
          cancelEdit();
        }}
      >
        <span className="hidden-xs">Cancel</span>
        <SvgIcon className="visible-xs" size="18" icon="close" color="grey" />
      </Button>
      <Button
        disabled={updating}
        onClick={submitEdit || handleUpdate}
        bsStyle="primary"
        className="ml--"
      >
        <span className="hidden-xs">Save</span>
        <SvgIcon className="visible-xs" size="17" icon="tick" color="white" />
      </Button>
    </Fragment>
  );

  const successTick = !updating &&
    instanceIsUpdating &&
    updated &&
    !editMode && <SvgIcon size="25" icon="tick_circle" color="#0fe2af" />;

  return isOwner ? (
    <div className={`${editWrp} right`} style={style}>
      <Prompt
        when={editMode}
        message="Are you sure you want to leave your unsaved changes?"
      />
      {successTick}
      {instanceIsUpdating && updating ? (
        <Loader loading className={loader} />
      ) : editMode ? (
        saveCancel
      ) : (
        pencil
      )}
    </div>
  ) : null;
};

Edit.propTypes = {
  canEdit: PropTypes.bool,
  editMode: PropTypes.bool.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  updateKey: PropTypes.string.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  onEmailActivate: PropTypes.func,
  isHeader: PropTypes.bool,
  style: PropTypes.object,
  submitEdit: PropTypes.func,
  updates: PropTypes.object,

  owner: PropTypes.object.isRequired,
};

export default memo(Edit);
